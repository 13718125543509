import React, { useEffect, useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import api from "../../utils/axios";
import { Box, Button, Center, Heading, Spinner } from "@chakra-ui/react";
import { UserContext } from "../../context/UserContext";
import { LANDING_PAGES } from "./landing-pages/LandingPage";
import { isAdmin } from "../../utils/helpers";

function Home() {
  const [eventName, setEventName] = useState("");
  const [eventName2, setEventName2] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { user, language } = useContext(UserContext);

  useEffect(() => {
    api.get("/api/events/upcoming").then((res) => {
      if (res.data.length > 0) {
        setEventName(res.data[0].eventName || "");
        setEventName2(res.data[0].eventName2 || "");
        setIsOpen(res.data[0].isOpen);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" marginTop="50px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!eventName) {
    return (
      <div>
        <Heading textAlign="center" margin="20px">
          Registration Closed
        </Heading>
        <Heading as="h4" size="md" textAlign="center">
          {language === "en"
            ? "Registration is not currently open at this time. Please contact your church if you have any questions."
            : "美東基督徒追求聚會的註冊登记手续尚未開啓. 若您有𨶹於特會的任何問題, 請與您的教會聯絡."}
        </Heading>
      </div>
    );
  }

  function landingPage() {
    if (eventName in LANDING_PAGES) {
      const LandingPage = LANDING_PAGES[eventName];
      return <LandingPage registrationIsOpen={isOpen} />;
    }

    return (
      <Heading as="h4" size="md" textAlign="center">
        {language === "en"
          ? `Welcome to ${eventName || ""} Registration!`
          : `歡迎您来到${eventName2 || ""}註册網站!`}
      </Heading>
    );
  }

  return (
    <div>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Home" : "首页"}
      </Heading>
      {landingPage()}
      <Center padding={10}>
        {/* Fix core dump while not logged in */}
        {/* <Button colorScheme="green">
          <RouterLink
            to={isAdmin(user) ? "/admin/registration" : "/registration"}
          >
            {language === "en" ? "Go to Registration" : "登记註册"}
          </RouterLink>
        </Button> */}
      </Center>
    </div>
  );
}

export default Home;
