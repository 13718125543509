import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  TableCaption,
  TableContainer,
  Text,
  Select,
  HStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import { ConfigContext } from "../../../context/ConfigContext";
import { Link as ReachLink } from "react-router-dom";
import api from "../../../utils/axios";
import { prettyPrintChurchShort } from "../../../utils/PrettyPrints";

function AdminStatisticsResidentCommuter() {
  const { language } = useContext(UserContext);
  const config = useContext(ConfigContext);
  const [churchStatistics, setChurchStatistics] = useState([]);
  const [registrationStatus, setRegistrationStatus] = useState("Accepted");

  const [totalFullTime, setTotalFullTime] = useState(0);
  const [totalPartTime, setTotalPartTime] = useState(0);
  const [totalCommutingAllMeals, setTotalCommutingAllMeals] = useState(0);
  const [totalCommutingPayAtCounter, setTotalCommutingPayAtCounter] =
    useState(0);

  function fetchReferenceData(status) {
    api.get(`/api/churches/statistics-reg-status/${status}`).then((res) => {
      let churchStatistics = [];
      let tempTotalFullTime = 0;
      let tempTotalPartTime = 0;
      let tempTotalCommutingAllMeals = 0;
      let tempTotalCommutingPayAtCounter = 0;
      res.data.forEach((church) => {
        let fullTime = 0;
        let partTime = 0;
        let commutingAllMeals = 0;
        let commutingPayAtCounter = 0;
        church.users.forEach((user) => {
          if (user.resident_commuter_status === "Full Time") {
            fullTime++;
            tempTotalFullTime++;
          } else if (user.resident_commuter_status === "Part Time") {
            partTime++;
            tempTotalPartTime++;
          } else if (
            user.resident_commuter_status === "Commuting - All Meals"
          ) {
            commutingAllMeals++;
            tempTotalCommutingAllMeals++;
          } else if (
            user.resident_commuter_status === "Commuting - Pay Meals at Counter"
          ) {
            commutingPayAtCounter++;
            tempTotalCommutingPayAtCounter++;
          }
        });
        churchStatistics.push({
          long_name: `${church.long_name}`,
          name: `${church.name}`,
          tier: `${church.tier}`,
          fullTime,
          partTime,
          commutingAllMeals,
          commutingPayAtCounter,
          subTotal:
            fullTime + partTime + commutingAllMeals + commutingPayAtCounter,
        });
      });
      setChurchStatistics(churchStatistics);
      setTotalFullTime(tempTotalFullTime);
      setTotalPartTime(tempTotalPartTime);
      setTotalCommutingAllMeals(tempTotalCommutingAllMeals);
      setTotalCommutingPayAtCounter(tempTotalCommutingPayAtCounter);
    });
  }

  useEffect(() => {
    fetchReferenceData("Accepted");
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en"
          ? "Resident/Commuter Statistics"
          : "住宿/通勤 統計數據"}
      </Heading>
      <HStack>
        <Text as="b">Registration Status:</Text>
        <Select
          value={registrationStatus}
          width={200}
          onChange={(e) => {
            fetchReferenceData(e.target.value);
            setRegistrationStatus(e.target.value);
          }}
        >
          <option value="All">All</option>
          {config.REGISTRATION_STATUSES.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
      </HStack>
      <TableContainer margin={5}>
        <Table variant="simple" size="sm">
          <TableCaption>
            {`Retreat Member Registration Counts - Only showing attending users with registration status: ${registrationStatus}`}
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Church</Th>
              <Th>Full Time</Th>
              <Th>Part Time</Th>
              <Th>Commuting - All Meals</Th>
              <Th>Commuting - Pay At Counter</Th>
              <Th>Subtotal</Th>
            </Tr>
          </Thead>
          <Tbody>
            {churchStatistics.map((church) => (
              <Tr key={church.long_name}>
                <Td>
                  <Tooltip label={church.long_name}>
                    {prettyPrintChurchShort(church)}
                  </Tooltip>
                </Td>
                <Td>{church.fullTime}</Td>
                <Td>{church.partTime}</Td>
                <Td>{church.commutingAllMeals}</Td>
                <Td>{church.commutingPayAtCounter}</Td>
                <Td fontWeight="bold">{church.subTotal}</Td>
              </Tr>
            ))}
            <Tr>
              <Td fontWeight="bold">Total</Td>
              <Td fontWeight="bold">{totalFullTime}</Td>
              <Td fontWeight="bold">{totalPartTime}</Td>
              <Td fontWeight="bold">{totalCommutingAllMeals}</Td>
              <Td fontWeight="bold">{totalCommutingPayAtCounter}</Td>
              <Td fontWeight="bold">
                {totalFullTime +
                  totalPartTime +
                  totalCommutingAllMeals +
                  totalCommutingPayAtCounter}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Button as={ReachLink} to="/admin/statistics" margin="0px 20px">
        {language === "en" ? "Back" : "回上頁"}
      </Button>
    </Box>
  );
}

export default AdminStatisticsResidentCommuter;
