import React, { useContext, useState } from "react";
import api from "../../../utils/axios";
import {
  useDisclosure,
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import { hasPermission } from "../../../utils/helpers";

function SendMassEmail() {
  const { user, language } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const [acceptedUsersOnly, setAcceptedUsersOnly] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  async function handleSubmit() {
    setErrorMessage("");
    setSuccessMessage("");

    if (subject.length === 0) {
      setErrorMessage("Subject cannot be empty.");
      return;
    }

    if (body.length === 0) {
      setErrorMessage("Body cannot be empty.");
      return;
    }

    try {
      const res = await api.post("/api/users/send-mass-email", {
        acceptedUsersOnly,
        subject,
        body,
      });

      const { count, emailList } = res.data;

      setSuccessMessage(`${count} emails sent successfully.`);
      console.log(emailList);
    } catch (err) {
      setErrorMessage("Unexpected error occurred");
      console.log(err);
    }
  }

  return (
    <>
      <Heading brand="brand" textAlign="center" margin="20px">
        {language === "en" ? "Send Mass Email" : "發送群體電郵"}
      </Heading>
      <Box display="flex" alignItems="center" justifyContent="center">
        <form style={{ width: "30%" }} onSubmit={onOpen}>
          <RadioGroup
            onChange={(e) => {
              setAcceptedUsersOnly(e === "2");
            }}
            value={acceptedUsersOnly ? "2" : "1"}
            marginBottom={5}
          >
            <VStack>
              <Radio value="1">Email all users</Radio>
              <Radio value="2">Email accepted users only</Radio>
            </VStack>
          </RadioGroup>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>
                {language === "en" ? "Subject" : "[Subject]"}
              </FormLabel>
              <Input
                type="text"
                placeholder={language === "en" ? "Subject" : "[Subject]"}
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{language === "en" ? "Body" : "[Body]"}</FormLabel>
              <Textarea
                placeholder={language === "en" ? "Body" : "[Body]"}
                value={body}
                onChange={(e) => {
                  setBody(e.target.value);
                }}
              />
            </FormControl>
            {errorMessage && (
              <Alert status="error">
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert status="success">
                <AlertIcon />
                {successMessage}
              </Alert>
            )}
            <Button
              colorScheme="red"
              onClick={onOpen}
              isDisabled={!hasPermission(user, "send_mass_email")}
            >
              {language === "en" ? "Send Mass Email" : "發送群體電郵"}
            </Button>
          </VStack>
        </form>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Send Mass Email
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Are you sure you want to do this? This will send this email to
              hundreds of people.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  handleSubmit();
                }}
                isDisabled={!hasPermission(user, "send_mass_email")}
                ml={3}
              >
                Send Email
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default SendMassEmail;
