import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminManageMeals() {
  const { user, language } = useContext(UserContext);

  const [meals, setMeals] = useState([]);

  function fetchReferenceData() {
    api.get("/api/meals").then((res) => {
      setMeals(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleDelete(d) {
    if (!hasPermission(user, "edit_reference_data_meals")) {
      // eslint-disable-next-line no-alert
      alert("You do not have permissions to delete meals.");
      return;
    }

    if (
      // eslint-disable-next-line no-alert
      window.confirm("Are you sure you wish to delete this meal?")
    ) {
      try {
        await api.delete(`/api/meals/${d._id}`);
        fetchReferenceData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("Meal deleted");
    }
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Meals
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Meal Name</Th>
              <Th>Date</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {meals &&
              meals.map((meal) => (
                <Tr key={meal._id}>
                  <Td>{meal.name}</Td>
                  <Td>
                    {meal.date
                      ? new Date(meal.date).toISOString().split("T")[0]
                      : ""}
                  </Td>
                  <Td>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_meals")
                          ? `/admin/reference-data/meals/${meal._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_meals")
                      }
                    >
                      {language === "en" ? "Update" : "更新"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(meal)}
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_meals")
                      }
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          {language === "en" ? "Back" : "回上頁"}
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_meals")
              ? "/admin/reference-data/meals/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_meals")}
        >
          {language === "en" ? "Add Meal" : "加新的三餐"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageMeals;
