import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";
import {
  prettyPrintBuilding,
  prettyPrintChurch,
} from "../../../../utils/PrettyPrints";

function AdminManageDorms() {
  const { user, language } = useContext(UserContext);

  const [dorms, setDorms] = useState([]);
  const [buildings, setBuildings] = useState([]);

  function fetchReferenceData() {
    api.get("/api/dorms").then((res) => {
      setDorms(res.data);
    });
    api.get("/api/buildings").then((res) => {
      setBuildings(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleDelete(d) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you wish to delete dorm ${d.room_number} ${d.name}?`
      )
    ) {
      try {
        await api.delete(`/api/dorms/${d._id}`);
        fetchReferenceData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("Dorm deleted");
    }
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Dorms
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Room Number</Th>
              <Th>Name</Th>
              <Th>Floor</Th>
              <Th>Current Occupancy</Th>
              <Th>Max Occupancy</Th>
              <Th>Description</Th>
              <Th>Building</Th>
              <Th>Church</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {dorms &&
              dorms.map((dorm) => (
                <Tr key={dorm._id}>
                  <Td>{dorm.room_number}</Td>
                  <Td>{dorm.name}</Td>
                  <Td>{dorm.floor}</Td>
                  <Td>{dorm.curr_occupancy}</Td>
                  <Td>{dorm.max_occupancy}</Td>
                  <Td>{dorm.description}</Td>
                  <Td>{prettyPrintBuilding(dorm.building?._id, buildings)}</Td>
                  <Td>{prettyPrintChurch(dorm.church)}</Td>
                  <Td>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_dorms")
                          ? `/admin/reference-data/dorms/${dorm._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_dorms")
                      }
                    >
                      {language === "en" ? "Update" : "更新"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(dorm)}
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_dorms")
                      }
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          {language === "en" ? "Back" : "回上頁"}
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_dorms")
              ? "/admin/reference-data/dorms/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_dorms")}
        >
          {language === "en" ? "Add Dorm" : "加新的宿舍"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageDorms;
