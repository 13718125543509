import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminAddEditClassroom() {
  const { user, language } = useContext(UserContext);
  const { classroomId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [buildings, setBuildings] = useState([]);

  const [classroomNumber, setClassroomNumber] = useState("");
  const [name, setName] = useState("");
  const [floor, setFloor] = useState("");
  const [description, setDescription] = useState("");
  const [building, setBuilding] = useState(null);

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/classrooms/${classroomId}`).then((res) => {
        setClassroomNumber(res.data.classroom_number?.toString() || "");
        setName(res.data.name || "");
        setFloor(res.data.floor || "");
        setDescription(res.data.description || "");
        setBuilding(res.data.building?._id);
      });
    }
    api.get("/api/buildings").then((res) => {
      setBuildings(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    const classroomObj = {
      classroom_number: parseInt(classroomNumber.trim(), 10),
      name: name.trim(),
      floor: floor.trim(),
      description: description.trim(),
      ...(building && { building }),
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/classrooms`, classroomObj);
      } else {
        await api.put(`/api/classrooms/${classroomId}`, classroomObj);
      }
      navigate("/admin/reference-data/classrooms");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Classroom" : "Edit Classroom"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Classroom Number:</FormLabel>
            <Input
              placeholder={
                language === "en" ? "Classroom Number" : "(Classroom Number)"
              }
              value={classroomNumber}
              onChange={(e) => setClassroomNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Classroom Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Name" : "(Name)"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Floor:</FormLabel>
            <Input
              placeholder={language === "en" ? "Floor" : "(Floor)"}
              value={floor}
              onChange={(e) => setFloor(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description: </FormLabel>
            <Input
              placeholder={language === "en" ? "Description" : "(Description)"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Building: </FormLabel>
            <Select
              placeholder={
                language === "en" ? "Select Building" : "(Select Building)"
              }
              value={building ?? ""}
              onChange={(e) => setBuilding(e.target.value || null)}
            >
              {buildings.length > 0 &&
                buildings.map((b) => (
                  <option value={b._id} key={b._id}>
                    {`${b.name} (${b.building_type})`}
                  </option>
                ))}
            </Select>
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/classrooms"
              margin="0px 20px"
            >
              {language === "en" ? "Back" : "回上頁"}
            </Button>
            <Button
              isDisabled={
                !hasPermission(user, "edit_reference_data_classrooms")
              }
              colorScheme="blue"
              type="submit"
            >
              {language === "en" ? "Submit" : "提交"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminAddEditClassroom;
