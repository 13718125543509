import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import api from "../../utils/axios";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { UserContext } from "../../context/UserContext";
import { isAdmin, isUser } from "../../utils/helpers";
// import usSVG from "./us.svg";
// import cnSVG from "./cn.svg";

function prettyPrintUserLabel(user) {
  const { language } = useContext(UserContext);

  let label = "";
  if (user) {
    label += language === "en" ? `${user.firstName} ${user.lastName}` : (
      user.chineseName ? `${user.chineseName}` : `${user.firstName} ${user.lastName}`
    );

    if (isAdmin(user)) {
      label += ` - ${user.role?.name}`;

      if (user.roleChurchId) {
        // For UserContext, roleChurchId is populated with the church object
        label += ` (${user.roleChurchId?.name})`;
      }
    }
  }
  return label;
}

export default function HeaderNav() {
  const { user, setUser, language, toggleLanguage } = useContext(UserContext);
  const { isOpen, onToggle } = useDisclosure();

  const navigate = useNavigate();
  const [eventName, setEventName] = useState("");
  const [eventName2, setEventName2] = useState("");

  useEffect(() => {
    api.get("/api/events/upcoming").then((res) => {
      if (res.data.length > 0) {
        setEventName(res.data[0].eventName || "");
        setEventName2(res.data[0].eventName2 || "");
      }
    });
  }, []);

  const navItems = [
    {
      label: language === "en" ? "Home" : "首页",
      href: "/",
    },
    ...(user && isUser(user)
      ? [
        {
          label: language === "en" ? "Registration" : "註冊登记",
          href: "/registration",
        },
      ]
      : []),
    ...(isAdmin(user)
      ? [
        {
          label: language === "en" ? "Registration" : "註冊登记",
          href: "/admin/registration",
        },
        (user?.role?.name === 'DA') ?
          {
            label: language === "en" ? "Assign Dorms" : "分配宿舍",
            subLabel: language === "en" ? "Assign Users to Dorms" : "分配參加特會者的宿舍",
            href: "/admin/assign-dorms",
          } : (
            {
              label: language === "en" ? "Admin Panel" : "管理區",
              navChildren: [
                {
                  label: language === "en" ? "Assign Groups" : "分配群組",
                  subLabel: language === "en" ? "Assign Users to Groups" : "分配參加特會者的群組",
                  href: "/admin/assign-groups",
                },
                {
                  label: language === "en" ? "Assign Dorms" : "分配宿舍",
                  subLabel: language === "en" ? "Assign Users to Dorms" : "分配參加特會者的宿舍",
                  href: "/admin/assign-dorms",
                },
                {
                  label:
                    language === "en" ? "Check Users in/out" : "報到和離營",
                  subLabel:
                    language === "en"
                      ? "Manage users' check-in and check-out times"
                      : "參會者的報到和離營時間",
                  href: "/admin/checked-in-out",
                },
                {
                  label:
                    language === "en"
                      ? "Manage Registration Status"
                      : "設定註册狀態",
                  subLabel:
                    language === "en"
                      ? "Manage users' registration status"
                      : "設定註册狀態",
                  href: "/admin/manage-registration-status",
                },
                {
                  label: language === "en" ? "Reference Data" : "參考資料",
                  subLabel:
                    language === "en"
                      ? "Manage groups, dorms, churches, etc."
                      : "設定參考資料",
                  href: "/admin/reference-data",
                },
                {
                  label: language === "en" ? "Statistics" : "統計數據",
                  subLabel:
                    language === "en"
                      ? "View registration statistics"
                      : "查看統計數據",
                  href: "/admin/statistics",
                },
                {
                  label:
                    language === "en" ? "Send Mass Email" : "發送群體電郵",
                  subLabel:
                    language === "en"
                      ? "Send custom email to all users or accepted users"
                      : "發送電郵給全體用戶或參加特會的用戶",
                  href: "/admin/send-mass-email",
                },
              ],
            }),
      ]
      : []),
  ];

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align="center"
      >
        <Flex
          // flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily="heading"
            color={useColorModeValue("gray.800", "white")}
          >
            {language === "en"
              ? `${eventName} Registration`
              : `${eventName2} 註册`}
          </Text>
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav navItems={navItems} />
          </Flex>
        </Flex>
        <Stack
          // flex={{ base: 1, md: 0 }}
          display={{ base: "none", md: "flex" }}
          justify="flex-end"
          alignItems="center"
          direction="row"
          spacing={6}
        >
          {user ? (
            <>
              <Text minWidth="100px">{prettyPrintUserLabel(user)}</Text>
              <Button
                padding={1}
                fontSize="md"
                fontWeight={600}
                variant="link"
                onClick={() => {
                  user?.role?.permissions?.includes("change_user_passwords")
                    ? navigate(`/admin/change-password/${user._id}`)
                    : navigate("/registration/change-password");
                }}
              >
                {language === "en" ? "Change password" : "更改密码"}
              </Button>
              <Button
                padding={6}
                fontSize="md"
                fontWeight={600}
                onClick={() => {
                  api.post("/api/auth/logout");
                  localStorage.removeItem("userData");
                  setUser(null);
                  navigate("/");
                }}
              >
                {language === "en" ? "Sign Out" : "登出"}
              </Button>
            </>
          ) : (
            <>
              <Button fontSize="md" fontWeight={400} variant="link">
                <RouterLink to="/login">
                  {language === "en" ? "Sign In" : "登入"}
                </RouterLink>
              </Button>
              <Button
                display={{ base: "none", md: "inline-flex" }}
                fontSize="md"
                fontWeight={600}
                color="white"
                bg="pink.400"
                href="#"
                _hover={{
                  bg: "pink.300",
                }}
              >
                <RouterLink to="/signup">
                  {language === "en" ? "Sign Up" : "登记"}
                </RouterLink>
              </Button>
            </>
          )}
        </Stack>
        <Button
          colorScheme="blue"
          onClick={toggleLanguage}
          marginLeft={{ base: 0, md: 6 }}
        >
          {language === "en" ? "中文" : "English"}
        </Button>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav navItems={navItems} />
      </Collapse>
    </Box>
  );
}

function DesktopNav({ navItems }) {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction="row" spacing={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Link
                as={RouterLink}
                to={navItem.href ?? "#"}
                p={2}
                fontSize="md"
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>
            {navItem.navChildren && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm"
              >
                <Stack>
                  {navItem.navChildren.map((child) => (
                    <DesktopSubNav
                      key={child.label}
                      label={child.label}
                      href={child.href}
                      subLabel={child.subLabel}
                    />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
}

function DesktopSubNav({ label, href, subLabel }) {
  return (
    <Link
      as={RouterLink}
      to={href ?? "#"}
      role="group"
      display="block"
      p={2}
      rounded="md"
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction="row" align="center">
        <Box>
          <Text
            transition="all .3s ease"
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize="sm">{subLabel}</Text>
        </Box>
        <Flex
          transition="all .3s ease"
          transform="translateX(-10px)"
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify="flex-end"
          align="center"
          flex={1}
        >
          <Icon color="pink.400" w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
}

function MobileNav({ navItems }) {
  const { user, setUser, language } = useContext(UserContext);

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {navItems.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          label={navItem.label}
          navChildren={navItem.navChildren}
          href={navItem.href}
        />
      ))}
      <Stack
        // flex={{ base: 1, md: 0 }}
        justify="flex-start"
        alignItems="start"
        direction="column"
        spacing={6}
      >
        {user ? (
          <>
            <Text minWidth="100px">{prettyPrintUserLabel(user)}</Text>
            <Button
              padding={6}
              fontSize="md"
              fontWeight={600}
              onClick={() => {
                api.post("/api/auth/logout");
                localStorage.removeItem("userData");
                setUser(null);
                navigate("/");
              }}
            >
              {language === "en" ? "Sign Out" : "登出"}
            </Button>
          </>
        ) : (
          <>
            <Button fontSize="md" fontWeight={400} variant="link">
              <RouterLink to="/login">
                {language === "en" ? "Sign In" : "登入"}
              </RouterLink>
            </Button>
            <Button
              fontSize="md"
              fontWeight={600}
              color="white"
              bg="pink.400"
              href="#"
              _hover={{
                bg: "pink.300",
              }}
            >
              <RouterLink to="/signup">
                {language === "en" ? "Sign Up" : "登记"}
              </RouterLink>
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
}

function MobileNavItem({ label, navChildren, href }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={navChildren && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {navChildren && (
          <Icon
            as={ChevronDownIcon}
            transition="all .25s ease-in-out"
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>
      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align="start"
        >
          {navChildren &&
            navChildren.map((child) => (
              <Link as={RouterLink} key={child.label} py={2} to={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}
