import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminManageBuildings() {
  const { user, language } = useContext(UserContext);

  const [buildings, setBuildings] = useState([]);

  function fetchReferenceData() {
    api.get("/api/buildings").then((res) => {
      setBuildings(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  function getBuildingString(b) {
    return `${b.name} (${b.building_type})`;
  }

  async function handleDelete(b) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you wish to delete building ${getBuildingString(b)}?`
      )
    ) {
      try {
        await api.delete(`/api/buildings/${b._id}`);
        fetchReferenceData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("Building deleted");
    }
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Buildings
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Building Type</Th>
              <Th>Description</Th>
              <Th>Street No</Th>
              <Th>Street Name</Th>
              <Th>City</Th>
              <Th>State</Th>
              <Th>Zip Code</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {buildings &&
              buildings.map((building) => (
                <Tr key={building._id}>
                  <Td>{building.name}</Td>
                  <Td>{building.building_type}</Td>
                  <Td>{building.description}</Td>
                  <Td>{building.address?.streetNo}</Td>
                  <Td>{building.address?.streetName}</Td>
                  <Td>{building.address?.city}</Td>
                  <Td>{building.address?.state}</Td>
                  <Td>{building.address?.zipCode}</Td>
                  <Td>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_buildings")
                          ? `/admin/reference-data/buildings/${building._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_buildings")
                      }
                    >
                      {language === "en" ? "Update" : "更新"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_buildings")
                      }
                      colorScheme="red"
                      onClick={() => handleDelete(building)}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          {language === "en" ? "Back" : "回上頁"}
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_buildings")
              ? "/admin/reference-data/buildings/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_buildings")}
        >
          {language === "en" ? "Add Building" : "加新的建築大樓"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageBuildings;
