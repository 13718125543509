import React, { createContext, useEffect, useState } from "react";
import api from "../utils/axios";

export const ConfigContext = createContext();

export function ConfigContextProvider({ children }) {
  const [data, setData] = useState("");

  useEffect(() => {
    api.get("/api/config").then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <ConfigContext.Provider value={data}>{children}</ConfigContext.Provider>
  );
}
