import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Heading } from "@chakra-ui/react";
import { UserContext } from "../../context/UserContext";
import RegistrationForm from "../../components/registration-form/RegistrationForm";

function AdminRegistrationDetail() {
  const { language } = useContext(UserContext);
  const location = useLocation();
  const [headerText, setHeaderText] = useState("");

  useEffect(() => {
    if (location.pathname.split("/").pop() === "add") {
      if (language === "en") {
        setHeaderText("Add Family Member");
      } else {
        setHeaderText("新家庭成员登記");
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (language === "en") {
        setHeaderText("Update Family Member");
      } else {
        setHeaderText("變更家庭成员資料");
      }
    }
  });

  return (
    <div>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Registration Detail" : "登记註册 祥資"}
      </Heading>
      <Heading as="h3" size="lg" textAlign="center">
        {headerText}
      </Heading>
      <RegistrationForm />
    </div>
  );
}

export default AdminRegistrationDetail;
