import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";
import styles from "./AdminManageChurches.module.css"; // Proof of concept - undo later

function AdminManageChurches() {
  const { user, language } = useContext(UserContext);

  const [churches, setChurches] = useState([]);

  function fetchReferenceData() {
    api.get("/api/churches").then((res) => {
      setChurches(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleDelete(d) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm("Are you sure you wish to delete this church?")
    ) {
      try {
        await api.delete(`/api/churches/${d._id}`);
        fetchReferenceData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("Church deleted");
    }
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Churches
      </Heading>
      <TableContainer>
        <Table className={styles.respTable}>
          <Thead className={styles.respTable}>
            <Tr className={styles.respTable}>
              <Th className={styles.respTableCell}>Name</Th>
              <Th className={styles.respTableCell}>Long Name</Th>
              <Th className={styles.respTableCell}>Street No</Th>
              <Th className={styles.respTableCell}>Street Name</Th>
              <Th className={styles.respTableCell}>City</Th>
              <Th className={styles.respTableCell}>State</Th>
              <Th className={styles.respTableCell}>Zip Code</Th>
              <Th className={styles.respTableCell}>Tier</Th>
              <Th className={styles.respTableCell}>Contact Name</Th>
              <Th className={styles.respTableCell}>Contact Email</Th>
              <Th className={styles.respTableCell}>Contact Phone</Th>
            </Tr>
          </Thead>
          <Tbody className={styles.respTable}>
            {churches &&
              churches.map((church) => (
                <Tr key={church._id} className={styles.respTable}>
                  <Td className={styles.respTableCell}>{church.name}</Td>
                  <Td className={styles.respTableCell}>{church.long_name}</Td>
                  <Td className={styles.respTableCell}>
                    {church.address?.streetNo}
                  </Td>
                  <Td className={styles.respTableCell}>
                    {church.address?.streetName}
                  </Td>
                  <Td className={styles.respTableCell}>
                    {church.address?.city}
                  </Td>
                  <Td className={styles.respTableCell}>
                    {church.address?.state}
                  </Td>
                  <Td className={styles.respTableCell}>
                    {church.address?.zipCode}
                  </Td>
                  <Td className={styles.respTableCell}>{church.tier}</Td>
                  <Td className={styles.respTableCell}>
                    {church.contact?.name}
                  </Td>
                  <Td className={styles.respTableCell}>
                    {church.contact?.email}
                  </Td>
                  <Td className={styles.respTableCell}>
                    {church.contact?.phone}
                  </Td>
                  <Td className={styles.respTableCell}>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_churches")
                          ? `/admin/reference-data/churches/${church._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_churches")
                      }
                    >
                      {language === "en" ? "Update" : "更新"}
                    </Button>
                  </Td>
                  <Td className={styles.respTableCell}>
                    <Button
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_churches")
                      }
                      colorScheme="red"
                      onClick={() => handleDelete(church)}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          {language === "en" ? "Back" : "回上頁"}
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_churches")
              ? "/admin/reference-data/churches/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_churches")}
        >
          {language === "en" ? "Add Church" : "加新的教會"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageChurches;
