import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminManageGroups() {
  const { user, language } = useContext(UserContext);

  const [groups, setGroups] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [leaders, setLeaders] = useState([]);

  function fetchReferenceData() {
    api.get("/api/groups").then((res) => {
      setGroups(res.data);
    });
    api.get("/api/classrooms").then((res) => {
      setClassrooms(res.data);
    });
    api.get("/api/users").then((res) => {
      setLeaders(res.data.filter((user) => user.leader === true));
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleDelete(g) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`Are you sure you wish to delete group id ${g.group_id}?`)
    ) {
      try {
        await api.delete(`/api/groups/${g._id}`);
        fetchReferenceData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("Group deleted");
    }
  }

  function getClassroomString(cObj) {
    return `Room ${cObj.classroom_number}, Floor ${cObj.floor}, Building ${cObj.building?.name}`;
  }

  function prettyPrintClassroom(classroomId) {
    if (classroomId == null) {
      return "";
    }

    const cObj = classrooms.find((c) => c._id === classroomId);
    if (cObj == null) {
      return "";
    }

    return getClassroomString(cObj);
  }

  function getLeaderString(lObj) {
    return `${lObj.firstName} ${lObj.lastName} (${lObj.email})`;
  }

  function prettyPrintLeader(leaderId) {
    if (leaderId == null) {
      return "";
    }

    const lObj = leaders.find((l) => l._id === leaderId);
    if (lObj == null) {
      return "";
    }

    return getLeaderString(lObj);
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Groups
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Group Id</Th>
              <Th>Name</Th>
              <Th>Language</Th>
              <Th>Description</Th>
              <Th>Current Occupancy</Th>
              <Th>Max Occupancy</Th>
              <Th>Classroom</Th>
              <Th>Leader</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {groups &&
              groups.map((group) => (
                <Tr key={group._id}>
                  <Td>{group.group_id}</Td>
                  <Td>{group.name}</Td>
                  <Td>{group.language}</Td>
                  <Td>{group.description}</Td>
                  <Td>{group.curr_occupancy}</Td>
                  <Td>{group.max_occupancy}</Td>
                  <Td>{prettyPrintClassroom(group.classroom?._id)}</Td>
                  <Td>{prettyPrintLeader(group.leader?._id)}</Td>
                  <Td>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_groups")
                          ? `/admin/reference-data/groups/${group._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_groups")
                      }
                    >
                      {language === "en" ? "Update" : "更新"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(group)}
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_groups")
                      }
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          {language === "en" ? "Back" : "回上頁"}
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_groups")
              ? "/admin/reference-data/groups/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_groups")}
        >
          {language === "en" ? "Add Group" : "加新的群組"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageGroups;
