import React, { createContext, useState } from "react";

const baseFilters = {
  firstName: "",
  lastName: "",
  chineseName: "",
  email: "",
  attending: "",
  commuting: "",
  checkedIn: "",
  checkedOut: "",
  familyId: "",
  groupName: "",
  groupLanguage: "",
  registrationStatus: "",
  hasSpecialNeeds: "",
  church: "",
  age: ["", ""],
  grade: ["", ""],
  gender: "",
  role: "",
};

export const TableContext = createContext();

export function TableContextProvider({ children }) {
  const [searchFilter, setSearchFilter] = useState("");
  const [filters, setFilters] = useState({ ...baseFilters });

  const value = { searchFilter, setSearchFilter, filters, setFilters };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}
