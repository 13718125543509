import React, { useContext } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Box, Button, Heading } from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import { hasPermission } from "../../../utils/helpers";

function AdminStatistics() {
  const { user, language } = useContext(UserContext);

  if (!hasPermission(user, "view_statistics")) {
    return (
      <>
        <Heading textAlign="center" margin="20px">
          {language === "en" ? "Statistics" : "統計數據"}
        </Heading>
        <Heading size="md" textAlign="center" margin="20px">
          {language === "en"
            ? "You do not have permission to view statistics."
            : "[You do not have permission to view statistics.]"}
        </Heading>
      </>
    );
  }

  return (
    <>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Statistics" : "統計數據"}
      </Heading>
      <Box textAlign="center" margin="40px 0px 0px 0px">
        <Button as={ReachLink} to="resident-commuter-status" margin="0px 10px">
          {language === "en"
            ? "Resident/Commuter Status"
            : "住宿或通勤"}
        </Button>
        <Button as={ReachLink} to="grades" margin="0px 10px">
          {language === "en" ? "Grades" : "班級或职份"}
        </Button>
        <Button as={ReachLink} to="dorms" margin="0px 10px">
          {language === "en" ? "Dorms" : "宿舍"}
        </Button>
      </Box>
    </>
  );
}

export default AdminStatistics;
