import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminAddEditLodging() {
  const { user, language } = useContext(UserContext);
  const { lodgingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [lodgingName, setLodgingName] = useState("");
  const [lodgingDate, setLodgingDate] = useState(null);
  const [errorDate, setErrorDate] = useState(false);

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/lodgings/${lodgingId}`).then((res) => {
        setLodgingName(res.data.name || "");
        setLodgingDate(new Date(res.data.date) || null);
      });
    }
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    if (!hasPermission(user, "edit_reference_data_lodging")) {
      // eslint-disable-next-line no-alert
      alert("You do not have permissions to edit lodgings.");
      return;
    }

    if (!lodgingDate) {
      setErrorDate(true);
      return;
    }
    setErrorDate(false);

    const lodgingObj = {
      name: lodgingName,
      date: lodgingDate,
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/lodgings`, lodgingObj);
      } else {
        await api.put(`/api/lodgings/${lodgingId}`, lodgingObj);
      }
      navigate("/admin/reference-data/lodging");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Lodging" : "Edit Lodging"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Lodging Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Name" : "(Name)"}
              value={lodgingName}
              onChange={(e) => setLodgingName(e.target.value)}
            />
          </FormControl>
          <FormControl isInvalid={errorDate}>
            <FormLabel>
              {language === "en" ? "Lodging Date" : "(Lodging Date)"}
            </FormLabel>
            <Input
              placeholder="Select Lodging Date"
              size="md"
              type="date"
              value={lodgingDate ? lodgingDate.toISOString().split("T")[0] : ""}
              onChange={(e) => {
                if (Number.isNaN(Date.parse(e.target.value))) {
                  setLodgingDate(null);
                } else {
                  setLodgingDate(new Date(e.target.value));
                }
              }}
              isDisabled={!hasPermission(user, "edit_reference_data_lodging")}
            />
            <FormErrorMessage>Date is required.</FormErrorMessage>
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/lodging"
              margin="0px 20px"
            >
              {language === "en" ? "Back" : "回上頁"}
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!hasPermission(user, "edit_reference_data_lodging")}
            >
              {language === "en" ? "Submit" : "提交"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminAddEditLodging;
