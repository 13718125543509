import { Tooltip } from "@chakra-ui/react";

export function prettyPrintUser(user) {
  if (!user) {
    return "";
  }

  let name = user.firstName || "";

  if (user.lastName) {
    name += " " + user.lastName;
  }

  if (user.chineseName) {
    name += ` (${user.chineseName})`;
  }

  return name;
}

export function prettyPrintDorm(user) {
  if (!user?.dorm) {
    return "Dorm: None";
  }

  return `Dorm: ${user.dorm?.building?.name} - Room ${
    user.dorm?.room_number || "N/A"
  }${user.dorm?.name ? " " + user.dorm?.name : ""}, Floor ${
    user.dorm?.floor || "N/A"
  }`;
}

export function prettyPrintChurchShort(church) {
  if (!church) {
    return "";
  }

  if (!church.tier || church.tier <= 0) {
    return church.name;
  }

  return church.name + "*".repeat(church.tier);
}

export function prettyPrintChurch(church) {
  if (!church) {
    return "";
  }

  return `${church.name} (${church.long_name})`;
}

export function prettyPrintBuilding(buildingId, buildings) {
  if (buildingId == null) {
    return "";
  }

  const bObj = buildings.find((b) => b._id === buildingId);
  if (bObj == null) {
    return "";
  }

  if (bObj.building_type) {
    return `${bObj.name} (${bObj.building_type})`;
  }

  return bObj.name;
}

export function formatPhoneNumber(input) {
  let val = input.replace(/\D/g, "");
  const size = val.length;
  if (size > 0) {
    val = `(${val}`;
  }
  if (size > 3) {
    val = `${val.slice(0, 4)}) ${val.slice(4, 11)}`;
  }
  if (size > 6) {
    val = `${val.slice(0, 9)} - ${val.slice(9)}`;
  }

  return val;
}

export function prettyPrintLastName(user) {
  if (!user) {
    return "";
  }

  if (user?.leader && user?.registerer) {
    return (
      <Tooltip label={"Registerer and Group Leader"}>
        {user.lastName + "***"}
      </Tooltip>
    );
  }

  if (user?.registerer) {
    return <Tooltip label={"Registerer"}>{user.lastName + "*"}</Tooltip>;
  }

  if (user?.leader) {
    return <Tooltip label={"Group Leader"}>{user.lastName + "**"}</Tooltip>;
  }

  return user.lastName;
}
