import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../utils/axios";
import { Box, Heading } from "@chakra-ui/react";

function ConfirmAcceptance() {
  const initialized = useRef(false);
  const { token } = useParams();
  const [updated, setUpdated] = useState(false);
  const [tokenError, setTokenError] = useState(false);

  async function validateToken() {
    try {
      const response = await api.post(
        `/api/users/validate-registration-status-token/${token}`
      );
      if (response.status === 200) {
        setUpdated(true);
      }
    } catch (error) {
      setTokenError(true);
    }
  }

  useEffect(() => {
    // This is necessary to prevent useEffect from running twice in StrictMode
    if (!initialized.current) {
      initialized.current = true;
      validateToken();
    }
  }, []);

  if (tokenError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <Heading textAlign="center" margin="20px">
          Confirm Acceptance Error 確認錯誤
        </Heading>
        <Heading textAlign="center" as="h5" size="sm">
          Problem confirming acceptance. Token may be expired. Please contact an
          admin for assistance.
          確認接受參加特會出現錯詇。鏈接可能過期失效，請與我们聯絡。
        </Heading>
      </Box>
    );
  }

  if (updated) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <Heading textAlign="center" margin="20px">
          Confirm Acceptance
        </Heading>
        <p>
          You have successfully confirmed your acceptance to the retreat! When
          you log in, you should see your updated registration status.
        </p>
        <p>您成功的確認接受了參加東岸特會!</p>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin={10}
    >
      <Heading textAlign="center" margin="20px">
        Confirm Acceptance
      </Heading>
      <p>Validating Token...</p>
    </Box>
  );
}

export default ConfirmAcceptance;
