import React from "react";
import api from "../utils/axios";
import { Button } from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { hasPermission } from "../utils/helpers";

export default class DownloadUsersCSVButton extends React.Component {
  static stringifyObjectFields = (obj) => {
    const newObj = { ...obj };
    Object.entries(newObj).forEach(([key, value]) => {
      if (typeof value === "object") {
        newObj[key] = JSON.stringify(value).replace(/,/g, " ");
      }
    });

    return newObj;
  };

  static convertToSingleDepth = (value) => {
    if (value == null) {
      return null;
    }

    if (typeof value === "object") {
      const newObj = { ...value };
      Object.entries(value).forEach(([objKey, objVal]) => {
        const destructuredObj = this.convertToSingleDepth(objVal);

        if (destructuredObj != null && typeof destructuredObj === "object") {
          Object.entries(destructuredObj).forEach(
            ([innerObjKey, innerObjVal]) => {
              const newKey = `${objKey}_${innerObjKey}`;
              newObj[newKey] = innerObjVal;
            }
          );
          delete newObj[objKey];
        }
      });

      return newObj;
    }

    return value;
  };

  csvLink = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      users: [],
    };
  }

  fetchAttendingUsers = () => {
    api.get("/api/users/csvAttending").then((res) => {
      const populatedUsers = res.data.map((user) => {
        return this.constructor.convertToSingleDepth(user);
        // return this.constructor.stringifyObjectFields(user);
      });

      this.setState({ users: populatedUsers }, () => {
        // click the CSVLink component to trigger the CSV download
        this.csvLink.current.link.click();
      });
    });
  };

  fetchAllUsers = () => {
    api.get("/api/users/csv").then((res) => {
      const populatedUsers = res.data.map((user) => {
        return this.constructor.convertToSingleDepth(user);
        // return this.constructor.stringifyObjectFields(user);
      });

      this.setState({ users: populatedUsers }, () => {
        // click the CSVLink component to trigger the CSV download
        this.csvLink.current.link.click();
      });
    });
  };

  render() {
    const { users } = this.state;

    return (
      <>
        <Button
          type="button"
          colorScheme={this.props.attendingUsers ? "blue" : "green"}
          marginTop={2}
          marginLeft={3}
          marginBottom={6}
          marginRight={3}
          onClick={
            this.props.attendingUsers
              ? this.fetchAttendingUsers
              : this.fetchAllUsers
          }
          isDisabled={!hasPermission(this.props.user, "download")}
        >
          Export {this.props.attendingUsers ? "Attending and Accepted" : "All"}{" "}
          Users to CSV
        </Button>
        <CSVLink
          data={users}
          ref={this.csvLink}
          style={{ display: "none" }}
          filename="registration_user_data.csv"
          target="_blank"
        />
      </>
    );
  }
}
