import React, { useState, useContext } from "react";
import api from "../../utils/axios";
import { Box, Button, Heading, Input, Stack } from "@chakra-ui/react";
import { UserContext } from "../../context/UserContext";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [emailSendSuccess, setEmailSendSuccess] = useState(false);
  const { language } = useContext(UserContext);

  async function sendEmail(e) {
    e.preventDefault();

    setEmailSendSuccess(false);
    setErrorMessage("");

    if (email === "") {
      setErrorMessage("The email address cannot be empty.");
      return;
    }

    try {
      const response = await api.post(`/api/auth/send-reset-email/${email}`);
      if (response.status === 200) {
        setEmailSendSuccess(true);
      }
    } catch (err) {
      setErrorMessage(
        err.response.data.message ||
          "An unexpected error occurred. Please try again later."
      );
    }
  }

  return (
    <>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Forgot Password" : "忘了密码"}
      </Heading>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <form onSubmit={sendEmail}>
          <Stack direction="column" spacing={5}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
            />
            <Button type="submit">
              {language === "en"
                ? "Send Password Reset Email"
                : "提交重設密码電邮"}
            </Button>
            {errorMessage.length > 0 && (
              <div>
                <p>{errorMessage}</p>
              </div>
            )}
            {emailSendSuccess && (
              <div>
                <p>
                  Password Reset Email Successfully Sent!
                  重新設定密码的電子邮件成功送出!
                </p>
              </div>
            )}
          </Stack>
        </form>
      </Box>
    </>
  );
}

export default ForgotPassword;
